import styled from 'styled-components'

export interface ButtonProps {
  variant: 'primary' | 'secondary' | "tertiary" | "tertiary_inverted"
  size: 'small' | 'medium' | 'large';
  border?: boolean;
  disabled?: boolean;
}

const height = {
  "small": 33,
  "medium": 46,
  "large": 60,
}

const fontSize = {
  "small": 14,
  "medium": 18,
  "large": 20,
}

const padding = {
  "small": "5px 16px 6px",
  "medium": "8px 24px 9px",
  "large": "14px 32px",
}

const iconSize = {
  "small": 16,
  "medium": 18,
  "large": 23.5,
}

const color = {
  'primary': ({ theme }) => theme.secondary,
  'secondary': ({ theme, disabled }) => disabled ? "#8A8A8A" : theme.primary,
  'tertiary': ({ theme }) => theme.secondary,
  'tertiary_inverted': ({ theme }) => theme.primary,
}

const background = {
  'primary': ({ theme, disabled }) => disabled ? "#A8A8A8" : theme.primary,
  'secondary': ({ theme }) => theme.secondary,
  'tertiary': 'transparent',
  'tertiary_inverted': 'transparent',
}

const hoverBackground = {
  'primary': ({ theme }) => theme.primary,
  'secondary': ({ theme }) => theme.secondary,
  'tertiary': 'rgba(24, 24, 24, 0.2)',
  'tertiary_inverted': 'rgba(24, 24, 24, 0.2)',
}

const activeBackground = {
  'primary': "#2E2E2E",
  'secondary': "#E6E6E6",
  'tertiary': 'rgba(24, 24, 24, 0.4)',
  'tertiary_inverted': 'rgba(24, 24, 24, 0.4)',
}


export const Button = styled.button<ButtonProps>`
  background: none;
  font: inherit;
  line-height: 1.6;
  padding: ${({ size }) => padding[size]};
  width: fit-content;
  font-size: ${({ size }) => fontSize[size]}px;
  height: ${({ size }) => height[size]}px;
  font-weight: 600;
  font-style: normal;
  color: ${({ variant }) => color[variant]};
  background-color: ${({ variant }) => background[variant]};
  border-radius: 6px;
  border: ${({ theme, border, variant }) => border ? `1px solid ${color[variant]({ theme })}` : 'none'};
  &:hover:not(:disabled) {
    background-color: ${({ variant }) => hoverBackground[variant]};
    cursor: pointer;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  }
  &:active {
    background-color: ${({ variant }) => activeBackground[variant]};
  }
  img {
    height: ${({ size }) => iconSize[size]}px;
    margin-bottom: 0;
    position: relative;
    top: ${({ size }) => size === "large" ? 4 : 3}px;
    &.right {
      margin-left: 8px;
    }
    &.left {
      margin-right: 8px;
    }
  }
`

Button.displayName = "Button"