import { useRef, useLayoutEffect } from 'react'
import { isInView } from '../util/browserUtil';

export const useScrollToBottom = (cb: () => void) => {
  const containerRef = useRef<HTMLElement>(null)
  const cbRef = useRef<() => void>();
  cbRef.current = cb;
  if (typeof window === "undefined") return containerRef;

  const handleScroll = () => {
    if (isInView(containerRef, { detectAtBottom: true })) {
      cbRef.current();
    }
  }

  useLayoutEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll)
  }, []);

  return containerRef;
}