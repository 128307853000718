import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import { Article } from '../types'

const getLatestArticles = gql`
  query GetLatestArticles($limit: Int, $nextToken: String, $authorId: String, $type: String, $categoryId: String) {
    getContents(filter: {type: {eq: $type}, author: {eq: $authorId}, category: { eq: $categoryId }}, limit: $limit, nextToken: $nextToken) {
      items {
        id
        slug
        title
        subtitle
        thumbnail
        publishDate
        attributes {
          name
          value
        }
        category {
          name
          color
        }
      }
      nextToken
    }
  }
`

type ArticleQueryResult = {
  getContents: {
    items: Partial<Article>[];
    nextToken: string;
  }
}

export const useLatestArticles = (limit: number, nextToken: string, authorId = "", type = "article", categoryId = null) => {
  return useQuery<ArticleQueryResult>(getLatestArticles, {
    fetchPolicy: 'no-cache',
    variables: { limit, nextToken, authorId, type, categoryId },
  })
}