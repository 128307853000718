import { Link as GatsbyLink } from 'gatsby'
import styled from 'styled-components'

import { font } from '../../util'

export const Link = styled(GatsbyLink)`
  white-space: nowrap;
  text-decoration: none;
  ${font.c6}
  line-height: 150%;
  color: #2683FC;
`