import React, { useEffect } from 'react'
import styled from 'styled-components'

import { breakpoint } from '../../util/styleUtil'
import CloseIcon from '../../assets/icons/Ico-Close.svg'
import { useOutsideClick, useScrollLocking } from '../../hooks'

type ModalProps = {
  open: boolean;
  onClose: () => void;
}

export const Modal: React.FC<ModalProps> = props => {
  const { open, onClose, children } = props
  const ref = useOutsideClick(onClose);
  useScrollLocking(open);
  return <ModalOverlay open={open} aria-modal aria-labelledby="modal-title">
    <StyledModal ref={ref}>
      <CloseButton onClick={onClose} aria-label="close">
        <CloseIcon height="16" width="16"/>
      </CloseButton>
      {children}
    </StyledModal>
  </ModalOverlay>
}

const ModalOverlay = styled.div`
  display: ${({ open }) => open ? 'flex' : 'none'};
  justify-content: flex-center;
  align-items: flex-start;
  ${breakpoint.mobile`align-items: center;`}
  @media (max-height: 700px){
    align-items: flex-start;
  }
  position: fixed;
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4);
`

const StyledModal = styled.div`
  background: ${({ theme }) => theme.bg};
  width: 96%;
  padding: 2rem;
  margin: 2% auto;
  ${breakpoint.mobile`
    width: min(80%, 800px);
    border-radius: 16px;
    box-shadow: 0px 6px 10px rgba(13, 13, 13, 0.15);
    position: relative;

  `}
`;
const CloseButton = styled.button`
  position: absolute;
  border: none;
  top: 0;
  right: 0;
  cursor: pointer;
  background: ${({ theme }) => theme.bg};
  top: 16px;
  right: 16px;
  ${breakpoint.mobile`
    top: -24px;
    right: -24px;
    box-shadow: 0px 6px 10px rgba(13, 13, 13, 0.15);
    border-radius: 50%;
    width: 48px;
    height: 48px;
    display: flex;
  `}
  >svg {
    margin: auto;
  }
`