import React from 'react'
import styled from 'styled-components'

import { facebookShareUrl, twitterShareUrl, emailShareUrl, openInPopup } from '../../util';
import Facebook from '../../assets/icons/facebook.svg'
import Twitter from '../../assets/icons/twitter.svg'
import Email from '../../assets/icons/email.svg'
import { Content } from '../../types';

type ShareLinksProps = {
  content: Content;
  template: string;
  emailSubject: string;
  emailTemplate?: string;
}

export const ShareLinks: React.FC<ShareLinksProps> = props => {
  const { content, template, emailSubject, emailTemplate, children } = props;

  const links = {
    facebook: facebookShareUrl(template, content),
    twitter: twitterShareUrl(template, content),
    email: emailShareUrl(content, emailTemplate || template, emailSubject),
  }

  return <StyledShareLinks>
      {children}
      <a href={links.facebook} target="_blank" rel="noopener" onClick={openInPopup()} title="share on facebook">
        <Facebook/>
      </a>
      <a href={links.twitter} target="_blank" rel="noopener" onClick={openInPopup()} title="share on twitter">
        <Twitter/>
      </a>
      <a href={links.email} target="_blank" rel="noopener" title="share by email">
        <Email/>
      </a>
    </StyledShareLinks>
}

const StyledShareLinks = styled.div`
  color: #666666;
  a {
    margin-left: 16px;
    svg {
      vertical-align: middle;
      path {
        fill: #666666;
      }
    }
  }
`
