export const getVideoEventMetrics = (video: any): Promise<[string, any]> => {
  // We need to delay the metrics collection to give the player time to reflect the current change
  return new Promise(resolve => {
    setTimeout(() => {
      let params: any = {}
      try {
        const media = video?.data?.media || {}
        params.videoId = media.hashedId;
        params.videoDuration = media.duration;
        params.videoName = media.name;
        params.mediaType = media.mediaType;

        const metrics = video?._impl?.behaviors?.relayEvents || {};
        params.ended = metrics._ended;
        params.hasPlayed = metrics._hasPlayed;
        params.isMuted = metrics._isMuted;
        params.videoQuality = metrics._lastQuality;
        params.state = metrics._lastState;
        params.timePosition = metrics._lastTimePosition;

        const timePercentage = params.timePosition / params.videoDuration;
        params.quarterComplete = timePercentage >= 0.25;
        params.halfComplete = timePercentage >= 0.5;
        params.threeQuartersComplete = timePercentage >= 0.75;
      } catch (error) {
        console.debug("An error occurred when parsing wistia video params");
        console.debug(error)
      }
      const state = ["paused", "playing"].includes(params.state) ? params.state : "interaction"
      resolve([`video_${state}`, params]);
    }, 100)
  })
}