import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { breakpoint, sectionPadding, STATIC_URL } from '../util'
import { Button, Section } from './ui'
import { NavLinks, NavItem } from './navLinks'
import FirstlyLogoSM from '../assets/icons/Firstly-Logo-SM.svg'

const contactUs = <Button
  as="a"
  href="mailto:support@firstly.com"
  title="support@firstly.com"
  variant="secondary"
  size="small"
  border
  className="button-link"
>
  Contact Us
</Button>;

const Header: React.FC = () => {
  return (
    <StyledHeader as="nav">
      <LogoContainer to={STATIC_URL.HOME}>
        <FirstlyLogoSM className="header-icon" aria-label="Firstly" />
      </LogoContainer>
      <NavItem className="app-buttons">
        {contactUs}
      </NavItem>
    </StyledHeader>
  )
}

const LogoContainer = styled(Link)`
  display: flex;
`

const StyledHeader = styled(Section)`
  position: fixed;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 12px 16px rgba(0, 0, 0, 0.04);
  z-index: 1;
  ${breakpoint.mobile`
    height: 93px;
  `}
  a {
    text-decoration: none;
    color: ${({ theme }) => theme.templateTextColor};
  }
  svg.header-icon {
    height: 24px;
    max-width: 65px;
    ${breakpoint.laptop`
      height: 48px;
      max-width: 130px;
    `}
    margin: 12px 0;
  }
`

export default Header
