import React, { ReactNode } from 'react'
import styled from 'styled-components'

import ChevronDown from '../../assets/icons/chevron-down.svg'
import ChevronUp from '../../assets/icons/chevron-up.svg'
import { breakpoint } from '../../util'



interface AccordionProps extends React.HTMLProps<HTMLDivElement> {
  title: string | ReactNode;
  field: string;
  current: string;
  onOpen: (field: string) => void;
}

export const Accordion: React.FC<AccordionProps> = props => {
  const { title, field, current, onOpen, children } = props
  const active = field === current;
  return <StyledAccordion onClick={() => field !== current && onOpen(field)}>
    <AccordionHeader active={active} className={`accordion-header ${active ? 'active' : ''}`}>
      <h2>{title}</h2>
      {active ? <ChevronUp/> : <ChevronDown />}
    </AccordionHeader>
    <AccordionContents active={active} className={`accordion-contents ${active ? 'active' : ''}`}>
      {children}
    </AccordionContents>
  </StyledAccordion>
}

const StyledAccordion = styled.div`

`

const AccordionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: ${({ active }) => active ? 3 : 1}px solid ${({ theme }) => theme.primary};
  h2 {
    margin-bottom: 16px;
  }
`

const AccordionContents = styled.div`
  height: 0px;
  overflow: hidden;
  transition: height 0.2s;
  &.active {
    min-height: fit-content;
    height: 820px;
    ${breakpoint.tablet`
      height: 220px;
    `}
  }
`