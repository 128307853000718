import React from 'react'
import { Button } from './button'

interface DynamicButtonProps extends React.HTMLProps<HTMLButtonElement> {
  attributes: any;
}

export const DynamicButton: React.FC<DynamicButtonProps> = props => {
  const { children, attributes, ...rest } = props;
  const {
    button_color_size,
    button_color_scheme,
    button_color_border,
    button_color_icon,
    button_color_icon_alt,
    button_color_icon_position,
  } = attributes
  
  const icon = <img loading='lazy' src={button_color_icon} className={button_color_icon_position} alt={button_color_icon_alt || "button icon"}/>

  return <Button
    variant={button_color_scheme}
    size={button_color_size}
    border={button_color_border === 'true'}
    {...rest}
  >
    {button_color_icon && button_color_icon_position === "left" ? icon : null}
    {children}
    {button_color_icon && button_color_icon_position === "right" ? icon : null}
  </Button>
}