/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from 'react'
import { Helmet } from 'react-helmet'

type Meta = {
  name: string;
  content: string;
} | {
  property: string;
  content: string;
}

type SEOProps = {
  title?: string;
  titleTemplate?: string;
  meta?: Meta[];
  lang?: string;
  canonical: string;
}

const staticMeta = [
  {
    property: "fb:app_id",
    content: "459134628680038",
  },
]

export const SEO: React.FC<SEOProps> = props => {
  const { title, titleTemplate = "%s | Firstly", lang = 'en', meta = [], canonical } = props

  return (
    // @ts-ignore (not sure why it imports the wrong type)
    <Helmet
      htmlAttributes={{ lang }}
      title={title}
      titleTemplate={titleTemplate}
      meta={meta.concat(staticMeta)}
    >
      <link rel="canonical" href={canonical} />
    </Helmet>
  )
}

export default SEO
