import React, { useState, useRef, useLayoutEffect } from 'react'

import { defaultViewDetectorOptions, isInView as defaultIsInView, ViewDetectorOptions } from '../util/browserUtil';

export const useViewDetector = (_options: ViewDetectorOptions = {}, isInView = defaultIsInView): ["in-view" | "", React.MutableRefObject<HTMLElement>] => {
  const options = { ...defaultViewDetectorOptions, ..._options };
  const ref = useRef<HTMLElement>(null)
  const [viewed, setViewed] = useState(false)

  if (typeof window === "undefined") return ['', ref];

  const handleScroll = () => {
    setViewed(viewed => (viewed && options.stayOn) || isInView(ref, options));
  }

  useLayoutEffect(() => {
    window.addEventListener('scroll', handleScroll);
    handleScroll();
    return () => window.removeEventListener('scroll', handleScroll)
  }, []);

  return [viewed ? 'in-view' : '', ref]
}