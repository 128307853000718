import { Content, ContentType } from "../types"

export const getUrlQuery = () => {
  return typeof window === "undefined" ? "" : window.location.search;
}

export const getArticleUrl = (slug: string) => {
  return `/articles/${slug}/`
}

export const getPodcastUrl = (slug: string) => {
  return `/podcasts/${slug}/`
}

export const getAuthorUrl = (slug) => {
  return `/authors/${slug}/`
}

export const getLandingPageUrl = (slug) => {
  return `/${slug}/`
}

export const prepExternalLink = (url: string) => {
  if (isInternalLink(url)) return url; // Starts with single slash. Don't change internal links
  if (/^https?:\/\/|\/\//.test(url)) return url; // Valid external url (starts with http or '//'). Change nothing
  return `//${url}`;
}

export const isInternalLink = (url: string) => {
  return /^\/(?!\/)/.test(url);
}

export const getVideoIdFromUrl = (url: string = "") => {
  return (url.match(/(?:stratfs|fast)\.wistia\.com\/(?:embed\/)?medias\/([a-z0-9]{10})/) || [])[1];
}


const contentRoutes = { "podcast": "/podcasts", "article": "/articles", "author": "/authors", "landingPage": "", };
export const getCurrentUrl = ({ type = "landingPage", slug }) => {
  if (typeof window === 'undefined') {
    return `${
      process.env.GATSBY_HOST_URL
    }${
      contentRoutes[type]
    }/${
      slug ? (slug + '/') : ''
    }`;
  }
  return window.location.origin + window.location.pathname;
}

export const facebookShareUrl = (template, content: Content) => {
  return `https://www.facebook.com/sharer/sharer.php?u=${getCurrentUrl(content)}&quote=${template} %0A${content.title} - ${getCurrentUrl(content)}`
}

export const twitterShareUrl = (template, content: Content) => {
  return `https://twitter.com/intent/tweet?url=${getCurrentUrl(content)}&text=${template} %0A${content.title}`
}

export const emailShareUrl = (content: Content, template, subject) => {
  return `mailto:?subject=${subject} - ${content.title}&body=Hi,%0D%0A%0D%0A${template}%0D%0A%0D%0A${content.title} - ${getCurrentUrl(content)}`;
}

export const getCategoryUrl = (path?: string) => {
  return `${STATIC_URL.ARTICLES}${(path || "").toLowerCase().replace(/ /g, "-")}${path ? "/" : ""}`
}

export const STATIC_URL = {
  HOME: "/",
  APP: "/meet-your-family-financial-goals-with-the-firstly-budgeting-app/",
  ARTICLES: "/articles/",
  PODCASTS: "/podcast/",
  ABOUT: "/about-firstly/",
  PRIVACY: "/privacy-policy/",
  TERMS: "/terms-and-conditions/",
  CCPA_DISCLOSURE: "/ccpa-disclosure/",
  SIGN_UP: process.env.GATSBY_APP_URL + "/signup/has-partner",
  SIGN_IN: process.env.GATSBY_APP_URL + "/login",
}
