import React, { useState } from 'react'
import styled from 'styled-components'

import { breakpoint, STATIC_URL } from '../util'
import CloseIcon from '../assets/icons/Ico-Close.svg'
import MenuIcon from '../assets/icons/Ico-Menu.svg'
import { Link } from 'gatsby'

export const NavItem = ({ children, tabletOnly = false, className = "" }) => <StyledNavItem className={className} tabletOnly={tabletOnly}>
  <span>{children}</span>
</StyledNavItem>;

export const NavLinks: React.FC<NavLinksProps> = props => {
  const { children } = props
  const [menuOpened, setMenuOpened] = useState(false);
  return <>
    <StyledNavLinks open={menuOpened}>
      <NavItem className="close-icon" tabletOnly>
        <CloseIcon
          className="mobile-menu-toggle"
          onClick={() => setMenuOpened(!menuOpened)}
          width="24"
          height="24"
        />
      </NavItem>
      <Link to={STATIC_URL.HOME} className="tablet-only">
        <NavItem tabletOnly>
          Home
        </NavItem>
      </Link>
      {children}
    </StyledNavLinks>
    <MenuIcon
      className="header-icon mobile-menu-toggle"
      onClick={() => setMenuOpened(!menuOpened)}
      width="24"
      height="24"
    />
  </>
}

export const StyledNavItem = styled.div`
  max-height: 96px;
  width: 100vw;
  padding: 10px 18px;
  display: flex;
  white-space: nowrap;
  ${breakpoint.tablet`
    width: unset;
    border-top: none;
    padding: 30px 12px;
    ${({ tabletOnly }) => tabletOnly ? 'display: none;' : ''}
  `}
  ${breakpoint.laptop`
    padding: 30px 16px;
  `}
  > span {
    width: 100%;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  &.close-icon svg {
    margin-left: auto;
  }
`

const StyledNavLinks = styled.div`
  display: ${({ open }) => open ? 'flex' : 'none'};
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  flex-direction: column;
  align-items: center;
  padding: 2px 0 22px;
  ${({ theme, open }) => open ? `background-color: ${theme.navbarBg}` : ''};
  
  ${breakpoint.tablet`
    display: flex;
    position: static;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 0;
    background-color: unset;
    > div:last-child, > a:last-child > div {
      padding-right: 0;
    }
    button {
      height: 46px;
      font-size: 18px;
      padding: "8px 24px 9px";
    }
    .tablet-only {
      display: none;
    }
  `}
`