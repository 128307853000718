import React from 'react'
import styled from 'styled-components'
import { STATIC_URL } from '../util';
import { DynamicButton } from './ui';

type SignUpButtonProps = {
  data?: any;
  attributes?: {
    button_color_scheme: string,
    button_color_size: string,
    button_color_border: string,
    message_text_color: string,
    button_color_icon: string,
    button_color_icon_position: string,
  };
  text?: string;
  className?: string;
}

const defaultButtonAttributes = {
  button_color_border: "false",
  button_color_scheme: "primary",
  button_color_size: "medium"
}

export const SignUpButton: React.FC<SignUpButtonProps> = props => {
  const { attributes = defaultButtonAttributes as any,
    text = "Sign up now",
    className,
    children,
  } = props;
  
  return <Container attributes={attributes} className={className}>
    {children}
    <DynamicButton attributes={attributes} as="a" href={STATIC_URL.SIGN_UP}>
      {text}
    </DynamicButton>
  </Container>
}

const Container = styled.div`
  margin-bottom: 26px;
  display:flex;
  align-items:center;
  justify-content:center;

  a {
    width:auto;
    margin:1rem 0 0;
    font-size:18px;
    font-weight:500;
    white-space:nowrap;
    padding-left: 24px;
    padding-right: 24px;
    :hover {
      text-decoration: none;
    }
  }
`