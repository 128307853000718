import React from 'react'
import styled from 'styled-components'

import { VideoPlayer } from './ui'
import { SignUpButton } from './signUpButton'
import { getVideoIdFromUrl, font } from '../util';

export const VideoPlayerModal: React.FC = props => {
  const { data } = props;
  return <StyledVideoPlayerModal>
    <VideoPlayer videoId={getVideoIdFromUrl(data.link)}/>
    <h2 id="modal-title">Firstly: Coming this fall!</h2>
    <SignUpButton />
  </StyledVideoPlayerModal>
}

const StyledVideoPlayerModal = styled.div`
  h2 {
    ${font.c2}
    text-align: center;
    color: #0D0D0D;
    padding-top: 32px;
  }
`