import React from 'react'
import styled from "styled-components"
import { Link } from "gatsby"

import { font } from "../../util"

import { STATIC_URL } from '../../util'

const defaultLinks = {
  home: {
    text: "Home",
    link: STATIC_URL.HOME,
  },
  articles: {
    text: "Articles",
    link: STATIC_URL.ARTICLES,
  },
  podcasts: {
    text: "Podcasts",
    link: STATIC_URL.PODCASTS,
  },
}

type BreadcrumbLink = keyof typeof defaultLinks;
type CustomLink = {
  text: string;
  link: string;
}

type NavigationBreadcrumbsProps = {
  links: BreadcrumbLink[];
  customLinks?: CustomLink[];
}

export const NavigationBreadcrumbs: React.FC<NavigationBreadcrumbsProps> = props => {
  const { links, customLinks = [] } = props;
  const allLinks = links.map(key => defaultLinks[key]).concat(customLinks)
  return <BreadcrumbsWrapper>
    {allLinks.map(({ text, link }) => <React.Fragment key={link}>
      &nbsp;
      <NavigationBreadcrumb to={link}>{text}</NavigationBreadcrumb>
      &nbsp;
      /
    </React.Fragment>)}
  </BreadcrumbsWrapper>
}

const BreadcrumbsWrapper = styled.span`
  ${font.c7}
  color: #666666;
`

const NavigationBreadcrumb = styled(Link)`
  text-decoration: none;
  color: #666666;
  :hover {
    color: #2683FC;
  }
`
