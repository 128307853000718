import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'

const mutation = gql`
  mutation addToWaitlist($input: AddToWaitlistInput!) {
    addToWaitlist(input: $input) {
      status
      message
    }
  }
`

export const useWaitlistMutation = () => {
  return useMutation(mutation)[0];
}