import { css, SimpleInterpolation, FlattenSimpleInterpolation } from 'styled-components'

/* Media queries */
export const screenWidth = {
  mobile: '34em',
  tablet: '40em',
  laptop: '65em',
  desktop: '76.25em',
}

type BreakpointUtils = {
  mobile: (css: any, ...interpolations: SimpleInterpolation[]) => FlattenSimpleInterpolation;
  tablet: (css: any, ...interpolations: SimpleInterpolation[]) => FlattenSimpleInterpolation;
  laptop: (css: any, ...interpolations: SimpleInterpolation[]) => FlattenSimpleInterpolation;
  desktop: (css: any, ...interpolations: SimpleInterpolation[]) => FlattenSimpleInterpolation;
}

/* For mobile-first design */
export const breakpoint = Object.keys(screenWidth).reduce((acc, key) => {
  acc[key] = (styles, ...rest) => css`
    @media screen and (min-width: ${screenWidth[key]}) {
      ${css(styles, ...rest)}
    }
  `
  return acc
}, {}) as BreakpointUtils;

export const font = {
  m1: css`
    font-family: Austin Web, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
      Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    font-weight: normal;
    font-size: 40px;
    ${breakpoint.mobile`
      font-size: 48px;
    `}
    ${breakpoint.tablet`
        font-size: 64px;
    `}
  `,
  m2: css`
    font-family: Ivy Presto Headline, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
    Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    font-weight: 600;
    font-size: 32px;
    ${breakpoint.mobile`
      font-size: 40px;
    `}
    ${breakpoint.tablet`
        font-size: 48px;
    `}
  `,
  m3: css`
    font-family: Ivy Presto Headline, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
    Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    font-weight: 600;
    font-size: 20px;
    ${breakpoint.mobile`
      font-size: 32px;
      font-weight: 600;
    `}
    ${breakpoint.tablet`
        font-size: 32px;
    `}
  `,
  m4: css`
    font-family: Ivy Presto Headline, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
      Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    font-weight: normal;
    font-size: 18px;
    ${breakpoint.mobile`
      font-size: 20px;
    `}
    ${breakpoint.tablet`
        font-size: 24px;
    `}
  `,
  m5: css`
    font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
      Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    font-weight: normal;
    font-size: 16px;
    ${breakpoint.mobile`
      font-size: 16px;
    `}
    ${breakpoint.tablet`
        font-size: 18px;
    `}
  `,
  m6: css`
    font-family: Austin Web, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
    Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    font-weight: normal;
    line-height: 130%;
    font-size: 32px;
    ${breakpoint.mobile`
      font-size: 40px;
    `}
    ${breakpoint.tablet`
        font-size: 48px;
    `}
  `,
  m7: css`
    font-family: Austin Web, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
    Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    font-weight: 500;
    line-height: 130%;
    font-size: 40px;
    ${breakpoint.mobile`
      font-size: 46px;
    `}
    ${breakpoint.tablet`
      font-size: 54px;
    `}
  `,
  c1: css`
    font-family: Ivy Presto Headline, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
      Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    font-weight: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 140%;
    ${breakpoint.mobile`
      font-size: 40px;
    `}
    ${breakpoint.tablet`
        font-size: 48px;
    `}
  `,
  c2: css`
    font-family: Ivy Presto Headline, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
    Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    font-weight: normal;
    font-size: 18px;
    line-height: 140%;
    ${breakpoint.mobile`
      font-size: 20px;
    `}
    ${breakpoint.tablet`
        font-size: 24px;
    `}
  `,
  c3: css`
    font-family: Ivy Presto Headline, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
    Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    font-weight: 600;
    font-size: 20px;
    line-height: 140%;
    ${breakpoint.mobile`
      font-size: 24px;
      font-weight: 600;
    `}
    ${breakpoint.tablet`
        font-size: 24px;
    `}
  `,
  c4: css`
    font-family: Ivy Presto Headline, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
      Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    font-weight: normal;
    font-size: 18px;
    line-height: 140%;
    ${breakpoint.mobile`
      font-size: 18px;
      font-weight: 600;
    `}
    ${breakpoint.tablet`
        font-size: 20px;
    `}
  `,
  c5: css`
    font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
      Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    ${breakpoint.mobile`
      font-size: 16px;
    `}
    ${breakpoint.tablet`
        font-size: 18px;
    `}
  `,
  c6: css`
    font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
      Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    font-weight: normal;
    font-size: 14px;
    line-height: 140%;
    ${breakpoint.mobile`
      font-size: 14px;
    `}
    ${breakpoint.tablet`
        font-size: 16px;
    `}
  `,
  c7: css`
    font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
      Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    font-weight: normal;
    font-size: 14px;
    line-height: 140%;
    ${breakpoint.mobile`
      font-size: 14px;
    `}
    ${breakpoint.tablet`
        font-size: 14px;
    `}
  `,
  c8: css`
    font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
      Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    font-weight: normal;
    font-size: 12px;
    line-height: 140%;
    ${breakpoint.mobile`
      font-size: 12px;
    `}
    ${breakpoint.tablet`
        font-size: 12px;
    `}
  `,
}

export const sectionPadding = `calc(50vw - 33rem)`;
export const negativeSectionPadding = `calc(-50vw + 33rem)`;

export const padExternallyUntilEdge = () => css`
  @media screen and (max-width: ${screenWidth.tablet}) {
    margin-left: -1rem;
    padding-left: 1rem;
    margin-right: -1rem;
    padding-right: 1rem;
  }
  ${breakpoint.tablet`
    &:first-of-type {
      margin-left: -1rem;;
      padding-left: 1rem;;
    }
    &:last-of-type {
      margin-right: -1rem;;
      padding-right: 1rem;;
    }
  `}
  ${breakpoint.desktop`
    &:first-of-type {
      margin-left: ${negativeSectionPadding};
      padding-left: ${sectionPadding};
    }
    &:last-of-type {
      margin-right: ${negativeSectionPadding};
      padding-right: ${sectionPadding};
    }
  `}
`

export const padExternallyUntilEdgeTablet = () => css`
  @media screen and (max-width: ${screenWidth.laptop}) {
    margin-left: -1rem;
    padding-left: 1rem;
    margin-right: -1rem;
    padding-right: 1rem;
  }
  ${breakpoint.laptop`
    &:first-of-type {
      margin-left: -1rem;;
      padding-left: 1rem;;
    }
    &:last-of-type {
      margin-right: -1rem;;
      padding-right: 1rem;;
    }
  `}
  ${breakpoint.desktop`
    &:first-of-type {
      margin-left: ${negativeSectionPadding};
      padding-left: ${sectionPadding};
    }
    &:last-of-type {
      margin-right: ${negativeSectionPadding};
      padding-right: ${sectionPadding};
    }
  `}
`

export const blurLeft = css`
  @media screen and (max-width: 2000px) {
    position: relative;
    margin-left: -75px;
    z-index: -2;
    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: inherit;
      margin-left: -1px;
      background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.5) 75px, rgba(255,255,255,0.0) 100px);
    }
  }
  @media screen and (max-width: 2150px) {
    margin-left: -75px;
  }
`