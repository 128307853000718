import styled from 'styled-components'

import CheckmarkIcon from '../../assets/icons/checkmark.svg'

type CheckmarkProps = {
  $background: string;
}

export const Checkmark = styled(CheckmarkIcon)<CheckmarkProps>`
  path:first-child {
    fill: ${({ background }) => background};
  }
`

