import { graphql, useStaticQuery } from 'gatsby'

import { Category } from '../types'

const getCategories = graphql`
  query {
    Content {
      getContentCategories {
        items {
          name
          color
          filter
          id
          path
          order
        }
      }
    }
  }
`

type CategoryQueryResult = {
  Content: {
    getContentCategories: {
      items: Category[]
    };
  }
}

export const useStaticCategories = () => {
  return (useStaticQuery<CategoryQueryResult>(getCategories)?.Content?.getContentCategories?.items || [])
    .filter(({ path }) => !!path)
    .sort((a, b) => a.order - b.order)
}