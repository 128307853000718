import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '../state/createStore'
import { openModal as triggerModal } from '../state/actions'
import { VideoPlayerModal } from './VideoPlayerModal'


import { Modal } from './ui'

export const GlobalModal: React.FC = props => {
  const dispatch = useDispatch();
  const { modalType, data } = useSelector((state: RootState) => state.modal);
  const ModalType = {
    VideoPlayerModal,
  }[modalType]
  return <Modal open={!!modalType} onClose={() => dispatch(triggerModal(""))}>
    {ModalType ? <ModalType data={data} /> : null}
  </Modal>
}