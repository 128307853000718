import React from "react"
import styled from "styled-components"

import { breakpoint, font } from "../../util"
import { Container } from "./container"

type HeroImageProps = {
  src: string;
  alt: string;
  caption: string;
  imageStyle?: any;
}

export const HeroImage: React.FC<HeroImageProps> = props => {
  const { src, alt, caption, imageStyle } = props
  return <HeroImageContainer>
    <img src={src} alt={alt} style={imageStyle}/>
    <span dangerouslySetInnerHTML={{ __html: caption }}/>
  </HeroImageContainer>
}

const HeroImageContainer = styled(Container)`
  img {
    min-width: 100%;
    margin-bottom: 0;
  }
  span {
    p {
      ${font.c7}
      text-align: left;
      margin-bottom: 0;
    }
  }
  margin-bottom: 24px;
  ${breakpoint.tablet`
    margin-bottom: 32px;
  `}
`