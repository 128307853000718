import { useState, useLayoutEffect, MutableRefObject } from 'react';
import { getCurrentUrl } from '../util';

export const useContainerSize = (ref: MutableRefObject<HTMLElement>) => {
  const [size, setSize] = useState({ width: 0, height: 0 });
  const update = () => {
    if (!ref.current) return;

    const target = ref.current.parentElement;

    setSize({
      width: target.clientWidth,
      height: target.clientHeight,
    })
  };
  useLayoutEffect(() => {
    if (typeof window === 'undefined') return;
    window.addEventListener('resize', update);
    update();
    return () => window.removeEventListener('resize', update);
  }, []);
  return { ...size, ref };
}