import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import { Content } from '../types'

const getPreviewQuery = gql`
  query GetPreviewById($id: String!) {
    getContentPreview(id: $id) {
      id
      slug
      title
      type
      publishDate
      subtitle
      tags
      thumbnail
      category {
        path
        filter
      }
      attributes {
        name
        value
      }
      sections {
        bodies {
          data
          type
          attributes {
            name
            value
          }
        }
        type
        attributes {
          name
          value
        }
      }
      authors {
        firstName
        id
        lastName
        bio
        slug
      }
    }
  }
`

type PreviewResult<T> = {
  getContentPreview: T;
}

export const usePreview = <T extends Content>(id: string) => {
  return useQuery<PreviewResult<T>>(getPreviewQuery, {
    fetchPolicy: 'no-cache',
    variables: { id },
  })
}