import React, { useEffect, useRef } from 'react';
import styled from 'styled-components'
import * as GTM from '../../util/GTMUtil'
import { getVideoEventMetrics } from '../../util/wistiaUtil';

type EmbedOptions = {
  autoPlay: any;
  controlsVisibleOnLoad: any;
  copyLinkAndThumbnailEnabled: any;
  doNotTrack: any;
  email: any;
  endVideoBehavior: any;
  fakeFullscreen: any;
  fitStrategy: any;
  fullscreenButton: any;
  fullscreenOnRotateToLandscape: any;
  googleAnalytics: any;
  muted: any;
  playbackRateControl: any;
  playbar: any;
  playButton: any;
  playerColor: any;
  playlistLinks: any;
  playlistLoop: any;
  playsinline: any;
  playSuspendedOffScreen: any;
  plugin: any;
  preload: any;
  qualityControl: any;
  qualityMax: any;
  qualityMin: any;
  resumable: any;
  seo: any;
  settingsControl: any;
  silentAutoPlay: any;
  smallPlayButton: any;
  stillUrl: any;
  time: any;
  videoFoam: any;
  volume: any;
  volumeControl: any;
  wmode: any;
}

type VideoPlayerProps = {
  videoId?: string;
  embedOptions?: Partial<EmbedOptions>;
  height?: string;
  width?: string;
  maxHeight?: string;
}

export const VideoPlayer: React.FC<VideoPlayerProps> = props => {
  const { videoId, embedOptions = {}, height = "415px", width = "100%", maxHeight = "50vw" } = props;
  const handle = useRef<any>(null);
  useEffect(() => {
    if (videoId && typeof window !== `undefined`) {
      // @ts-ignore
      window._wq = window._wq || [];
      // @ts-ignore
      window._wq.push({
        id: videoId,
        options: embedOptions,
        onHasData: (video) => {
          handle.current = video;
        },
      });
    }

    return () => handle.current && handle.current.remove();
  }, [videoId]);

  return <WistiaContainer
    data-testid="video-container"
    onClick={() => getVideoEventMetrics(handle.current).then(([type, params]) => GTM.trigger(type, params))}
    height={height}
    width={width}
    maxheight={maxHeight}
    className={`wistia_embed wistia_async_${videoId}`}
  >
    <PlaceholderImageContainer className="wistia_swatch">
      <PlaceholderImage src={`https://fast.wistia.com/embed/medias/${videoId}/swatch`} alt="" aria-hidden="true"/>
    </PlaceholderImageContainer>
  </WistiaContainer>
}

const WistiaContainer = styled.div`
  position: relative;
  max-height: ${({ maxheight }) => maxheight};
  height: ${({ height }) => height};
  width: ${({ width }) => width};
`

const PlaceholderImageContainer = styled.div`
  height: 100%;
  left: 0;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  transition: opacity 200ms;
  width: 100%;
`

const PlaceholderImage = styled.img`
  filter: blur(5px);
  height: 100%;
  objectFit: contain;
  width: 100%;
`