export const openInPopup = (height = 500, width = 500) => e => {
  const left = (window.screen.availWidth / 2) - (width / 2);
  const top =  (window.screen.availHeight / 2) - (height / 2);
  const newWindow = window.open(e.currentTarget.href, '_blank', `left=${left},top=${top},width=${width},height=${height}`)
  if (!newWindow) return true;
  e.preventDefault();
}

export const defaultViewDetectorOptions = {
  detectAtBottom: false,
  stayOn: true,
  turnOffAfterElement: false,
}
export type ViewDetectorOptions = Partial<typeof defaultViewDetectorOptions>;

const percentageInView = (boundingBox) => {
    if (!boundingBox) {
      return 0;
    }

    var height = boundingBox.height;

    var dHeight = (window.innerHeight || document.documentElement.clientHeight);

    // only accounting for vert in-percentage to be backward capatible
    return Math.max(0, 
        (
          (height + Math.min(0, boundingBox.top) 
            + Math.min(0, dHeight - boundingBox.bottom)
          ) / height
        )
      ) * 100;
  }

/**
 * Alternate approach to determining if something is in view
 * The biggest win here is the intersection will be based on the viewport, not the parent - important if you have nested position:relative|absolute;
 * You may also see some minor client-side browser performance improments
 *
 * Further reading: 
 * using bounding box to determine viewability. Preferred approach (with except of intersection observer)
 * https://stackoverflow.com/questions/1350581/how-to-get-an-elements-top-position-relative-to-the-browsers-viewport
 * 
 * To test this for triggering the loading/displaying of additional data you need to define
 *  The getBoundingClientRect on the element that should trigger the event 
 *  Explanation: https://github.com/jsdom/jsdom/issues/1590
 *   
 *  container.getBoundingClientRect = jest.fn(() => ({
 *     x: 0,
 *     width: 500,
 *     right: 500,
 *     left: 0,
 *
 *     top:0,
 *     y:0,
 *     height:499,
 *     bottom:499
 *   }));
 * 
 * @param ref 
 * @param _options 
 * @returns 
 */
export const isInViewByBoundingClientRect = (ref, _options: ViewDetectorOptions = {}) => {
  if (typeof window === "undefined" || !ref.current) return false;
  
  const options = { ...defaultViewDetectorOptions, ..._options };

  var boundingBox = ref.current.getBoundingClientRect();

  // this will get the offset from the viewport instead of the parent
  const percentInView = percentageInView(boundingBox);

  const alreadyScrollPassed = boundingBox.top <= -1;

  return (alreadyScrollPassed || percentInView > 0) 
    && (!options.turnOffAfterElement || percentInView === 100)
}

export const isInView = (ref, _options: ViewDetectorOptions = {}) => {
  if (typeof window === "undefined" || !ref.current) return false;
  const options = { ...defaultViewDetectorOptions, ..._options };

  const scrollLocation = window.scrollY + window.innerHeight;
  const bottomOfContainer = ref.current.offsetTop + ref.current.offsetHeight;
  const detectionStartPoint = options.detectAtBottom ? bottomOfContainer : ref.current.offsetTop;
  const isAfterStart = scrollLocation >= detectionStartPoint
  const isBeforeEnd = scrollLocation <= bottomOfContainer;
  return isAfterStart && (isBeforeEnd || !options.turnOffAfterElement)
}

export const scrollToElement = (ref, offset = 0) => {
  if (typeof window === "undefined" || !ref.current) return false;

  const target = ref.current.offsetTop - offset;
  window.scroll({
    top: target,
    left: 0,
    behavior: 'smooth'
  });
}

export const getNavHeight = () => {
  if (typeof window !== "undefined" && window.innerWidth >= 640) return 96;
  return 48
}