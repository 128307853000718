import { Content } from '../types'
import { getCurrentUrl } from './urls';

type RequiredMetaAttributeMap = {
  meta_title: string;
  meta_desc: string;
  social_image: string;
} 

export const mapMetaTags = (content: Content) => {
  const { title, type = "landingPage", slug = "", attributes = [] } = content;
  const attributeMap = attributes.reduce((all, { name, value }) => {
    all[name] = value;
    return all;
  }, {}) as RequiredMetaAttributeMap
  const metaTags = [
    {
      name: "description",
      content: attributeMap.meta_desc,
    },
    {
      property: "og:title",
      content: attributeMap.meta_title || title,
    },
    {
      property: "og:description",
      content: attributeMap.meta_desc,
    },
    {
      property: "og:image",
      content: attributeMap.social_image,
    },
    {
      property: "og:type",
      content: type === "landingPage" ? "website" : "article",
    },
    {
      name: "twitter:card",
      content: "summary_large_image",
    },
    {
      name: "twitter:title",
      content: attributeMap.meta_title || title,
    },
    {
      name: "twitter:description",
      content: attributeMap.meta_desc,
    },
    {
      name: "twitter:image",
      content: attributeMap.social_image,
    },
  ]

  const url = getCurrentUrl({ slug, type })
  if (url) {
    metaTags.push({
      property: "og:url",
      content: url,
    })
  }

  const hiddenPages = [
    'default',
    'podcast',
    'confirm-email',
  ]
  if (type === "podcast" || hiddenPages.includes(slug)) {
    metaTags.push({
      name: "robots",
      content: "noindex, nofollow",
    })
  }
  return metaTags
}

export const getBrowserTitle = (content: Content) => {
  return content?.attributes?.find(({ name }) => name === 'seo_title')?.value || content.title;
}