import { useRef, useEffect } from "react"

export const useOutsideClick = (cb = () => {}) => {
    const ref = useRef(null);
    const clickHandler = e => ref.current && !ref.current.contains(e.target) && cb();
    const escapeHandler = e => e.keyCode === 27 && cb();
    useEffect(() => {
        document.addEventListener("mousedown", clickHandler, true);
        document.addEventListener("touchend", clickHandler, true);
        document.addEventListener("keydown", escapeHandler);
    }, [])

    return ref;
}