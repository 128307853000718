import { useEffect } from "react"

const setScroll = value => {
  if (!window.document.scrollingElement) return;
  window.document.scrollingElement.style.["overflow-y"] = value;
}
  

export const useScrollLocking = isLocked => {
  useEffect(() => {
    if (typeof window === 'undefined') return;
    setScroll(isLocked ? 'hidden' : 'auto');
    return () => setScroll('auto')
  }, [isLocked])
}