import { createGlobalStyle } from 'styled-components'
import { font } from './util'

export const theme = {
  fg: '#181818',
  bg: '#FFFFFF',
  primary: "#181818",
  secondary: "#FFFFFF",
  navbarBg: '#FFFFFF',
  footerBg: '#E5E5E5',
  footerTextColor: 'black',
  templateTextColor: '#181818',
  calculatorColor: 'rgba(0, 50, 91, 1)',
  errorRed: '#AC1818',
}

type Theme = {
  theme: typeof theme;
}

export const GlobalStyle = createGlobalStyle<Theme>`
body {
  background:${props => props.theme.bg};
  color:${props => props.theme.fg};
  overflow: hidden;
  font-family: Inter, "Franklin Gothic Medium", Tahoma, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  #gatsby-focus-wrapper {
    position: relative;
  }
  h1 { ${font.m1} };
  h2 { ${font.m2} };
  h3 { ${font.m3} };
  h4, h5, h6 { ${font.m4} };
  p { ${font.m5} };
}
`
