import React from 'react'
import styled from 'styled-components'

import ErrorIcon from '../../assets/icons/Ico-Exclamation.svg'

interface InputProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  name: string;
  label: string;
  errors?: string[];
  assistiveText?: string;
  getValidationErrors?: (input: string | number | readonly string[]) => string | string[];
  onChange: (slice: any) => string;
}

export const Input: React.FC<InputProps> = props => {
  const { name, label, onChange = () => { }, assistiveText, errors = [], getValidationErrors = () => ([]), value, ...rest } = props
  const error = errors.concat(getValidationErrors(value))[0];
  return <InputContainer className="input-container" error={error}>
    <input type='text' value={value} placeholder={label} {...rest} onChange={e => onChange({ [name]: e.target.value })} />
    <ErrorIcon className="error-icon" title="error icon" />
    {(error || assistiveText) && (
      <HelperText>
        {error ? <ErrorMessage aria-errormessage>{error}</ErrorMessage> : assistiveText}
      </HelperText>
    )}
  </InputContainer>
}

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  position: relative;

  input {
    width: 100%;
    height: 46px;
    padding-left:.5rem;
    outline: none;

    appearance:none;
    border:none;
    background:0;
    /* @ts-ignore */
    ${({ error, theme }) => error ? `border-bottom: 1px solid ${theme.errorRed};` : 'border-bottom: 1px solid rgba(0,0,0,.6);'}
  }
  
  svg.error-icon {
    display: ${({ error }) => error ? 'block' : 'none'};
    position: absolute;
    right: 14px;
    top: 14px;
  }
`;

const HelperText = styled.span`
  min-height: 24px;
  font-size: 12px;
  font-style: italic;
  `

const ErrorMessage = styled.span`
  color: ${({ theme }) => theme.errorRed};
`
