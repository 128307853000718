import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import { Editor } from '../types'

const getEditorPreviewQuery = gql`
  query GetPreviewById($id: String!) {
    getEditorPreview(id: $id) {
      id
      slug
      firstName
      lastName
      bio
      email
      photo
      socialLinks {
        type
        value
      }
      slug
    }
  }
`

type PreviewResult = {
  getEditorPreview: Editor;
}

export const useEditorPreview = (id: string) => {
  return useQuery<PreviewResult>(getEditorPreviewQuery, {
    fetchPolicy: 'no-cache',
    variables: { id },
  })
}