import React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { Helmet } from 'react-helmet'

import { GlobalStyle, theme } from '../theme'
import { breakpoint } from '../util'
import Header from './header'
import { Footer } from './footer'
import './layout.css'
import { GlobalModal } from './globalModal'

export const Layout: React.FC = ({ children, headerOverlaps = false }) => {
  return (
    <ThemeProvider theme={theme}>
      {/* @ts-ignore (not sure why it imports the wrong type) */}
      <Helmet>
        <link rel="preconnect" href="https://fonts.gstatic.com"/>
        <link href="https://fonts.googleapis.com/css2?family=Inter&display=swap" rel="stylesheet"/>
        <script id="wistia_script" type="text/javascript" src="https://fast.wistia.com/assets/external/E-v1.js" async/>
      </Helmet>
      <GlobalStyle />
      <Header/>
      <ContentWrapper as="main" headerOverlaps={headerOverlaps}>{children}</ContentWrapper>
      <GlobalModal  />
      <Footer />
    </ThemeProvider>
  )
}

const ContentWrapper = styled.div`
  margin: 0 auto;
  min-height: calc(100vh - 10.1rem);
  ${({ headerOverlaps }) => headerOverlaps ? '' : 'padding-top: 48px;'}
  ${breakpoint.mobile`
    min-height: calc(100vh - 12rem);
  `}
  ${breakpoint.tablet`
    min-height: calc(100vh - 8.33rem);
    ${({ headerOverlaps }) => headerOverlaps ? '' : 'padding-top: 96px;'}
  `}
  ${breakpoint.laptop`
    min-height: calc(100vh - 8.33rem);
  `}
`
