import styled from 'styled-components'
import { breakpoint, sectionPadding, padExternallyUntilEdge } from '../../util'

export const Section = styled.div`
  width: 100%;
  padding: 0 1rem;
  ${breakpoint.desktop`padding: 0 ${sectionPadding};`}
`

export const StyledSection = styled(Section)`
  position: relative;
  ${({ attributes }) => attributes.minimum_height_percentage ? `min-height: ${attributes.minimum_height_percentage}vw;` : ""}
  ${({ attributes }) => attributes.background_color ? `background-color: ${attributes.background_color};` : ""}
  ${({ attributes }) => attributes.background_image ? `background-image: url(${attributes.background_image});` : ""}
  background-size: cover;
  background-position: center;
  .body-group {
    background-size: cover;
    background-position: center;
  }
`

export const BodyGroup = styled.div`
  &.body-group {
    padding: 2rem 1rem;
    ${padExternallyUntilEdge()}
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`

export const SingleColumn = styled(StyledSection)``

// helper for extracting the mobile order from attributes:
const mobileOrder = index => ({ attributes: { mobile_order = "[0,1,2]" } }) => JSON.parse(mobile_order)[index];

const hasBackground = attributes => attributes.background_color || attributes.background_image || attributes.background_video;

export const TwoColumns = styled(StyledSection)`
  .body-group {
    ${breakpoint.desktop`
      padding: 30px 1rem;
    `}
    ${breakpoint.tablet`
      order: unset !important;
    `}
  }
  display: flex;
  flex-direction: column;
  ${breakpoint.tablet`
    display: grid;
    grid-template-columns: ${({ attributes }) => `${attributes.column1_width || 50}% ${attributes.column2_width || 50}%`};
  `}
  .body-group:nth-child(1) {
    ${({ attributes }) => !hasBackground(attributes) && attributes.column1_background_color ? `background-color: ${attributes.column1_background_color};` : ""}
    ${({ attributes }) => !hasBackground(attributes) && attributes.column1_background_image ? `background-image: url(${attributes.column1_background_image});` : ""}
    order: ${mobileOrder(0)};
  }
  .body-group:nth-child(2) {
    ${({ attributes }) => !hasBackground(attributes) && attributes.column2_background_color ? `background-color: ${attributes.column2_background_color};` : ""}
    ${({ attributes }) => !hasBackground(attributes) && attributes.column2_background_image ? `background-image: url(${attributes.column2_background_image});` : ""}
    order: ${mobileOrder(1)};
  }
`

export const ThreeColumns = styled(StyledSection)`
  display: flex;
  flex-direction: column;
  ${breakpoint.tablet`
    display: grid;
    grid-template-columns: ${({ attributes }) => `${attributes.column1_width || 33.3}% ${attributes.column2_width || 33.3}% ${attributes.column3_width || 33.3}%`};
    .body-group {
      order: unset !important;
    }
  `}
  .body-group:nth-child(1) {
    ${({ attributes }) => attributes.column1_background_color ? `background-color: ${attributes.column1_background_color};` : ""}
    ${({ attributes }) => attributes.column1_background_image ? `background-image: url(${attributes.column1_background_image});` : ""}
    order: ${mobileOrder(0)};
  }
  .body-group:nth-child(2) {
    ${({ attributes }) => attributes.column2_background_color ? `background-color: ${attributes.column2_background_color};` : ""}
    ${({ attributes }) => attributes.column2_background_image ? `background-image: url(${attributes.column2_background_image});` : ""}
    order: ${mobileOrder(1)};
  }
  .body-group:nth-child(3) {
    ${({ attributes }) => attributes.column3_background_color ? `background-color: ${attributes.column3_background_color};` : ""}
    ${({ attributes }) => attributes.column3_background_image ? `background-image: url(${attributes.column3_background_image});` : ""}
    order: ${mobileOrder(2)};
  }
`