import { SET_USER, MODAL_OPEN } from './actionTypes'
import { ModalType } from '../types'

export const setUser = user => ({
  type: SET_USER,
  payload: user,
})

export const openModal = (modalType: ModalType | "", data = {} as any) => ({
  type: MODAL_OPEN,
  payload: {
    modalType,
    data,
  }
})